import PropTypes from 'prop-types';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
// @mui
import { Box, List, ListItemText } from '@mui/material';
//
import { StyledNavItem, StyledNavItemIcon } from './styles';
import Iconify from '../iconify/Iconify';
// ----------------------------------------------------------------------


// ----------------------------------------------------------------------

NavSection.propTypes = {
  navConfig: PropTypes.array,
};

export default function NavSection({ navConfig , ...other }) {
  const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;
  const { pathname } = useLocation();
  const roles = localStorage.getItem('role_name');

  const match = (path) =>
    path ? !!matchPath({ path, end: false }, pathname) : false;
    const sidebarConfig =
    roles === 'SuperAdmin'
      ? [
        {
          title: 'dashboard',
          path: '/dashboard/app',
          icon: getIcon('bi:pie-chart-fill'),
        },
        {
          title: 'about',
          path: '/dashboard/about',
          icon: getIcon('mdi:about-circle-outline'),
        },
        {
          title: ' categories',
          path: '/dashboard/categories',
          icon: getIcon('bxs:category'),
        },
        {
          title: ' items',
          path: '/dashboard/items',
          icon: getIcon('material-symbols:format-list-bulleted'),
        },
        {
          title: ' Staff section',
          path: '/dashboard/staff-section',
          icon: getIcon('tabler:tools-kitchen-2'),
        },
  
        {
          title: 'users',
          path: '/dashboard/users',
          icon: getIcon('clarity:group-solid'),
        },
        {
          title: 'tables',
          path: '/dashboard/tables',
          icon: getIcon('ri:table-fill'),
        },
        {
          title: 'orders',
          path: '/dashboard/orders',
          icon: getIcon('bxs:dish'),
        },
        {
          title: 'Languages',
          path: '/dashboard/languages',
          icon: getIcon('clarity:language-solid'),
        },
        {
          title: 'Currency',
          path: '/dashboard/currency',
          icon: getIcon('grommet-icons:currency'),
        },
      ]
      : [
        {
          title: 'orders',
          path: '/dashboard/orders',
          icon: getIcon('bxs:dish'),
        }, 
      ];
  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {sidebarConfig.map((item) => (
          <NavItem key={item.title} item={item} active={match}  />
        ))}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const { title, path, icon, info } = item;

  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        '&.active': {
          color: 'text.primary',
          bgcolor: 'action.selected',
          fontWeight: 'fontWeightBold',
        },
      }}
    >
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

      <ListItemText disableTypography primary={title} />

      {info && info}
    </StyledNavItem>
  );
}
