import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField, CircularProgress } from '@mui/material';
// import Iconify from '../../components/iconify';
import Alert from '@mui/material/Alert';
// import AlertTitle from '@mui/material/AlertTitle';
import Snackbar from '@mui/material/Snackbar';
import { useState } from 'react';
import axios from 'axios';
import { PERIOD_LOGIN } from 'src/constants/url';

const ChangePeriodLogin = ({ reload, setReload, period }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem('token');
  const [periodTime, setPeriodTime] = useState(period);
  console.log('periodTime', periodTime);
  const [message, setMessage] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setMessage(false);
  };
  const handleChangePeriod = async () => {
    setLoading(true);
    const data = {
      JWT_TTL: periodTime,
    };

    await axios
      .post(PERIOD_LOGIN, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setTimeout(() => {
            setReload(!reload);
          }, 1500);
          setMessage(true);
          setOpen(false);
        }
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });

    setLoading(false);
  };
  return (
    <>
      <Button variant="contained" onClick={handleClickOpen} className="btn-add">
        Change Period
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Change Period</DialogTitle>
        <DialogContent>
          <TextField
            label="Login Access Period"
            variant="filled"
            fullWidth
            className="mb-2"
            value={periodTime}
            onChange={(e) => setPeriodTime(e.target.value)}
          />
        </DialogContent>
        <DialogActions className="mb-4">
          {loading ? (
            <Button className=" btn-bg">
              <CircularProgress style={{ color: '#fff' }} size={30} />
            </Button>
          ) : (
            <Button className="btn-bg  text-white fs-6" onClick={handleChangePeriod}>
              Save
            </Button>
          )}
          <Button onClick={handleClose} className="btn-bg  bg-secondary text-white fs-6">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={message} autoHideDuration={2000} onClose={handleCloseMessage} className="ic">
        <Alert
          onClose={handleCloseMessage}
          severity="success"
          sx={{ width: '100%' }}
          style={{ background: 'rgb(46, 125, 50)', color: '#fff' }}
        >
          success! Login Access Period update Successfully
        </Alert>
      </Snackbar>
    </>
  );
};

export default ChangePeriodLogin;
