import { Outlet, useNavigate, Navigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { USER_AUTH } from './constants/url';
const PrivateRoutes = () => {
  const token = localStorage.getItem('token');
  const [userauth, setUserAuth] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const FetchUserInfo = async () => {
      await axios
        .get(USER_AUTH, {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setUserAuth(response.data.data);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            navigate('/login');
            localStorage.removeItem('token');
          }
        });
    };
    FetchUserInfo();
  }, [token]);

  return token ? <Outlet /> : navigate('/login');
};

export default PrivateRoutes;
