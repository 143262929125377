import axios from "axios";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { updateFcmToken } from "./constants/url";

const firebaseConfig = {
  apiKey: "AIzaSyDMnaV_d4I6Pmx_825V6iI5Sutp3vUQAoI",
  authDomain: "zucodiaqrmenu.firebaseapp.com",
  projectId: "zucodiaqrmenu",
  storageBucket: "zucodiaqrmenu.appspot.com",
  messagingSenderId: "1092312957099",
  appId: "1:1092312957099:web:2a12dd24836548d005f7ee",
  measurementId: "G-SS4P1MLXK7"
};

initializeApp(firebaseConfig); 

const messaging = getMessaging();

export const requestForToken = async (token) => {
  try {
    const currentToken = await getToken(messaging, {
      vapidKey:
        "BKS7sym70srmAhiwLYquR5cNvtAiQYdPgipLwCUdmiCp5AlOqbFq7S-Aj0vWmAZ8M-gZuw7LePOoBBRJ2I4JFyY",
    });
    if (currentToken) {
      if (token) {
        axios.post(
          updateFcmToken,
          { fcm_token: currentToken },
          {
            headers: {
              Authorization: "Bearer " + token,
              Accept: "application/json",
            },
          }
        );
      }
      // .then((response) => {
      //   console.log(response);
      // })
      // .catch((error) => {
      //   console.log(error);
      // });
    } else {
      // Show permission request UI
      console.log(
        "No registration token available. Request permission to generate one."
      );
    }
  } catch (err) {
    console.log("An error occurred while retrieving token. ", err);
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
