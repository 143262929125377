import {
  CircularProgress,
  Snackbar,
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import axios from 'axios';
import { useState } from 'react';
import { ORDERS } from 'src/constants/url';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
const UpdateOrder = ({ reload, setReload, id, item_order }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem('token');
  const [message, setMessage] = useState(false);
  const [list, setList] = useState(item_order.order_status);

  const newData = ["new", "working", "done", "delivered"]
  const index = newData.indexOf(item_order.order_status)

  console.log(newData)
  const handleChange = (e) => {
    setList(e.target.value);

  };


  const handleCloseMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setMessage(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const UpdateOrderForm = () => {
    setLoading(true);
    const data = {
      order_status: list,
    };
    axios
      .post(`${ORDERS}/${id}?_method=PUT`, data, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
      .then(() => {
        setMessage(true);
        setTimeout(() => {
          setReload(!reload);
        }, 1500);
        setOpen(false);
        setError(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <>
      {index === 3 ?
        <Button disabled variant="outlined" onClick={handleClickOpen} className="btn-bg fw-normal my-4 border-0 text-white" style={{opacity:'.5'}}>
          <ModeEditOutlineOutlinedIcon /> Update Order Status
        </Button>
        : <Button  variant="outlined" onClick={handleClickOpen} className="btn-bg fw-normal my-4 border-0 text-white" >
          <ModeEditOutlineOutlinedIcon /> Update Order Status
        </Button>}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Update Order Status</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <FormControl variant="filled" fullWidth>
              <InputLabel id="demo-simple-select-filled-label">Select Order Status</InputLabel>
              <Select
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled "
                value={list}
                onChange={handleChange}
              >
                {newData && newData.slice(index + 1, index + 2).map((item) => (
                  <MenuItem value={item} key={item} id={item} >{item}</MenuItem>
                ))}

                {/* <MenuItem value="new">New</MenuItem>
                <MenuItem value="Working">Working</MenuItem>
                <MenuItem value="done">Done</MenuItem>
                <MenuItem value="delivered" >Delivered</MenuItem> */}
              </Select>
            </FormControl>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="mb-4">
          {loading ? (
            <Button className=" btn-bg">
              <CircularProgress style={{ color: '#fff' }} size={30} />
            </Button>
          ) : (

            <Button className="btn-bg  text-white fs-6" onClick={UpdateOrderForm}>
              Save
            </Button>
          )}
          <Button onClick={handleClose} className="btn-bg  bg-secondary text-white fs-6">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={message} autoHideDuration={3000} onClose={handleCloseMessage} className="ic">
        <Alert
          onClose={handleCloseMessage}
          severity="success"
          sx={{ width: '100%' }}
          style={{ background: 'rgb(46, 125, 50)', color: '#fff' }}
        >
          success! Order Updated Successfully
        </Alert>
      </Snackbar>
    </>
  );
};

export default UpdateOrder;
