import ReactDOM from 'react-dom/client';


import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
// 
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
// import { LangContextProvider } from './store/LangContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    // <LangContextProvider>
        <HelmetProvider >
            <BrowserRouter >
                <Routes>
                    <Route path="/*" element={<App />} />
                </Routes>
            </BrowserRouter >
        </HelmetProvider >
    // </LangContextProvider>
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
