import { FormControl, InputLabel, MenuItem, Select, Skeleton } from '@mui/material';
import { useState, useEffect } from 'react';
import { ROLES } from 'src/constants/url';
import axios from 'axios';
const RolesList = (props) => {
  const token = localStorage.getItem('token');

  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(true);

  const FetchRoles = async () => {
    setLoading(true);
    await axios
      .get(ROLES, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setRoles(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    FetchRoles();
  }, []);
  return loading ? (
    <Skeleton width={'100%'} height={'5rem'} />
  ) : (
    <FormControl variant="filled" fullWidth>
      <InputLabel id="demo-simple-select-filled-label">Role Name</InputLabel>
      <Select
        labelId="demo-simple-select-filled-label"
        id="demo-simple-select-filled"
        value={props.select}
        onChange={(e) => props.setSelect(e.target.value)}
      >
        {roles.length > 0 ? (
          roles.map((item) => (
            <MenuItem value={item.name} id={item.id} key={item.id}>
              {item.name}
            </MenuItem>
          ))
        ) : (
          <p>no roles</p>
        )}
      </Select>
    </FormControl>
  );
};

export default RolesList;
