import { Button, Menu, Divider, Badge } from '@mui/material';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import { useState, useEffect } from 'react';

const NotificationsPopover = ({ notifications, loading }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id="basic-button"
        className="notifi-btn p-0"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <Badge badgeContent={notifications && notifications.length === 0 ? 0 : notifications.length} color="primary">
          <CircleNotificationsIcon />
        </Badge>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{
          '& .MuiList-root': {
            padding: '1rem',
          },
        }}
      >
        {loading ? (
          <p>Please Wait...</p>
        ) : notifications && notifications.length > 0 ? (
          notifications.map((item) => (
            <div onClick={handleClose} className="content p-3" id={item.id} key={item.id}>
            
              <p className="mb-1" style={{ wordBreak: 'break-all' }}>
                {item.body}
              </p>
              <Divider />
            </div>
          ))
        ) : (
          <p className="p-1 mb-0">NO Notification Yet</p>
        )}

        {/* <div className="text-center all-link py-3" onClick={handleClose}>
          <Link to="">View All</Link>
        </div> */}
      </Menu>
    </>
  );
};

export default NotificationsPopover;
