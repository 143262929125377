export const BaseUrl = 'https://api.smart-order-byblos.losyro.com/api';
export const STORAGE = 'https://api.smart-order-byblos.losyro.com/';
export const LOGIN = `${BaseUrl}/login`;
export const LOGOUT = `${BaseUrl}/logout`;
export const CATEGORIES = `${BaseUrl}/category`;
export const KITCHEN_SECTION = `${BaseUrl}/staffSection`;
export const ITEMS = `${BaseUrl}/item`;
export const USER = `${BaseUrl}/user`;
export const PERIOD_LOGIN = `${BaseUrl}/jwt_TTL`;
export const TABLE = `${BaseUrl}/table`;
export const ABOUT = `${BaseUrl}/about`;
export const DELETE_ABOUT_IMAGE = `${BaseUrl}/about/image/`;
export const USER_AUTH = `${BaseUrl}/me`;
export const ROLES = `${BaseUrl}/roles`;
export const ORDERS = `${BaseUrl}/order`;
export const NOTIFICATIONS = `${BaseUrl}/notification`;
export const updateFcmToken = `${BaseUrl}/update/fcm?_method=PUT`;
export const refreshToken = `${BaseUrl}/refresh`;
export const AVILABLE_LANG = `${BaseUrl}/avaliableLanguages`;
export const ADD_LANG = `${BaseUrl}/translations_languages`;
export const  CURRENCY = `${BaseUrl}/currency`;
