import {
  TextField,
  CircularProgress,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  //   DialogContentText,
  DialogTitle,
  Alert,
  AlertTitle,
  Snackbar,
} from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';
import axios from 'axios';
import { TABLE } from 'src/constants/url';

const CreateTable = ({ reload, setReload }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem('token');
  const [tablenum, setTableNum] = useState('');
  const [error, setError] = useState(false);
  const [erroralert, showErrorAlert] = useState([]);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setMessage(false);
  };
  const [item, setItem] = useState([]);

  const addNewItem = () => {
    const copyCart = [...item];
    copyCart.push(tablenum);
    setItem(copyCart);
    setTableNum("");
  };



  const CreateTableForm = () => {
    setLoading(true);
    const formData = new FormData();
    item.map((i) => formData.append(`tables[${i}]`, [i]));
    axios
      .post(TABLE, formData, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setMessage(true);
          setTimeout(() => {
            setReload(!reload);
          }, 1500);
          setOpen(false);
          setError(false);
          resetForm()
        }
      })

      .catch((error) => {
        setError(true);
        showErrorAlert(error.response.data.message);
      })
      .finally(() => {
        setLoading(false);
        setTableNum('');
      });
  };
  const resetForm = () => {
    setItem([])
  };



  const deleteByIndex = index => {
    setItem(oldValues => {
      return oldValues.filter((_, i) => i !== index)
    })
  }

  return (
    <>
      <Button variant="contained" onClick={handleClickOpen} className="btn-add">
        <AddIcon /> New Table
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Create Table</DialogTitle>
        <DialogContent>
          <>
            {error ? (
              <Alert severity="error" className="mb-4">
                <AlertTitle>Error</AlertTitle>
                <p>{erroralert}</p>
              </Alert>
            ) : (
              ''
            )}
            <TextField
              id="filled-basic"
              label="Table Number"
              value={tablenum}
              type="number"
              variant="filled"
              fullWidth
              onChange={(e) => setTableNum(e.target.value)}
              className="mb-2"
            />
            {tablenum === '' ? <Button disabled className="btn-bg bg-secondary text-white fs-6 mb-2" style={{ opacity: '.6' }}>
              Add Table
            </Button>
              : <Button onClick={addNewItem} className="btn-bg bg-secondary text-white fs-6 mb-2">
                Add Table
              </Button>
            }
            <ul className='list-unstyled m-0 '>
              {item.map((subItems, sIndex) => {
                return <li key={sIndex} id={sIndex} className='list-table'> {subItems}
                  <button onClick={() => deleteByIndex(sIndex)} className="bg-transparent border-0 text-white ">
                   Delete</button>
                

                </li>
              })}
            </ul>

          </>
        </DialogContent>
        <DialogActions className="mb-4">
          {loading ? (
            <Button className=" btn-bg">
              <CircularProgress style={{ color: '#fff' }} size={30} />
            </Button>
          ) : (
            <Button className="btn-bg  text-white fs-6" onClick={CreateTableForm}>
              Save
            </Button>
          )}
          <Button onClick={handleClose} className="btn-bg  bg-secondary text-white fs-6">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={message} autoHideDuration={3000} onClose={handleCloseMessage} className="ic">
        <Alert
          onClose={handleCloseMessage}
          severity="success"
          sx={{ width: '100%' }}
          style={{ background: 'rgb(46, 125, 50)', color: '#fff' }}
        >
          success! Table Added Successfully
        </Alert>
      </Snackbar>
    </>
  );
};

export default CreateTable;
