import { useState, useEffect } from 'react';
import axios from 'axios';
import { CATEGORIES } from 'src/constants/url';
import { InputLabel, MenuItem, FormControl, Select, Skeleton } from '@mui/material';
const CategorySection = (props) => {
  const token = localStorage.getItem('token');
  const [info, setInfo] = useState([]);
  const [infoLoaded, setInfoLoaded] = useState(false);

  const handleChange = (e) => {
    props.setcategory_section(e.target.value);
  };
  const FetchCategorySection = async () => {
    await axios
      .get(CATEGORIES, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setInfo(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error.response.data.message);
      })
      .finally(() => {
        setInfoLoaded(true);
      });
  };
  useEffect(() => {
    FetchCategorySection();
  }, []);
  return !infoLoaded ? (
    <Skeleton width={'100%'} height={'5rem'} />
  ) : (
    <FormControl variant="filled" fullWidth className="mt-2">
      <InputLabel id="demo-simple-select-filled-label">Select Category</InputLabel>
      <Select
        labelId="demo-simple-select-filled-label"
        id="demo-simple-select-filled"
        value={props.category_section}
        onChange={handleChange}
        name="category_section"
      >
        {info.length > 0 ? (
          info.map((item) => (
            <MenuItem value={item.id} key={item.id}>
              {item.translations.name.en}
            </MenuItem>
          ))
        ) : (
          <MenuItem>No Category</MenuItem>
        )}
      </Select>
    </FormControl>
  );
};

export default CategorySection;
