// component

import Iconify from '../../../components/iconify';
// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;
const roles = localStorage.getItem('role_name');

const navConfig =
  roles === 'SuperAdmin'
    ? [
      {
        title: 'dashboard',
        path: '/dashboard/app',
        icon: getIcon('bi:pie-chart-fill'),
      },
      {
        title: 'about',
        path: '/dashboard/about',
        icon: getIcon('mdi:about-circle-outline'),
      },
      {
        title: ' categories',
        path: '/dashboard/categories',
        icon: getIcon('bxs:category'),
      },
      {
        title: ' items',
        path: '/dashboard/items',
        icon: getIcon('material-symbols:format-list-bulleted'),
      },
      {
        title: ' Staff section',
        path: '/dashboard/staff-section',
        icon: getIcon('tabler:tools-kitchen-2'),
      },

      {
        title: 'users',
        path: '/dashboard/users',
        icon: getIcon('clarity:group-solid'),
      },
      {
        title: 'tables',
        path: '/dashboard/tables',
        icon: getIcon('ri:table-fill'),
      },
      {
        title: 'orders',
        path: '/dashboard/orders',
        icon: getIcon('bxs:dish'),
      },
      {
        title: 'Languages',
        path: '/dashboard/languages',
        icon: getIcon('clarity:language-solid'),
      },
      {
        title: 'Currency',
        path: '/dashboard/currency',
        icon: getIcon('grommet-icons:currency'),
      },
    ]
    : [
      {
        title: 'orders',
        path: '/dashboard/orders',
        icon: getIcon('bxs:dish'),
      }, 
    ];

export default navConfig;
