import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  TextField,
  InputAdornment,
  IconButton,
  CircularProgress,
  //   Grid,
  //   Select,
  //   FormControl,
  //   MenuItem,
  //   InputLabel,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Iconify from '../../components/iconify';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Snackbar from '@mui/material/Snackbar';
import { useState } from 'react';
import axios from 'axios';
import { USER } from 'src/constants/url';
import KitchenSection from '../KitchenSection';
import RolesList from '../RolesList';

const AddUser = ({ reload, setReload }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem('token');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [kitchen_section, setkitchen_section] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState(false);
  const [erroralert, showErrorAlert] = useState([]);
  const [empty, setEmpty] = useState('');
  const [select, setSelect] = useState('');
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setMessage(false);
  };
  const handleSubmitAddUser = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append('email', email);
    formData.append('name', name);
    formData.append('password', password);
    formData.append('role_name', select);
    if (select === 'Staff' || select === 'Captien' || select === 'client') {
      formData.append('staff_sections_id', kitchen_section);
    }

    await axios
      .post(USER, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setError(false);
          setMessage(true);
          setOpen(false);
          setTimeout(() => {
            setReload(!reload);
          }, 1500);
        }
      })
      .catch((error) => {
        setError(true);
        showErrorAlert(error.response.data.message);
        setEmpty('please add Staff Section then try to add Staff Section User');
      });

    setLoading(false);
  };

  return (
    <>
      <Button variant="contained" onClick={handleClickOpen} className="btn-add">
        <AddIcon /> New User
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Create User</DialogTitle>
        <DialogContent>
          <>
            {error ? (
              <Alert severity="error" className="mb-4">
                <AlertTitle>Error</AlertTitle>
                <p>{erroralert}</p>
                {select === 'Staff' || select === 'Captien' || (select === 'client' && kitchen_section == null)
                  ? empty
                  : ''}
              </Alert>
            ) : (
              ''
            )}
            <TextField
              label="Name"
              variant="filled"
              fullWidth
              className="mb-2"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              label="Email"
              variant="filled"
              fullWidth
              className="mb-2"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              fullWidth
              className="mb-2"
              variant="filled"
              name="password"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <RolesList select={select} setSelect={setSelect} />
            {select === 'Staff' || select === 'Captien' ? (
              <KitchenSection kitchen_section={kitchen_section} setkitchen_section={setkitchen_section} />
            ) : null}
          </>
        </DialogContent>
        <DialogActions className="mb-4">
          {loading ? (
            <Button className=" btn-bg">
              <CircularProgress style={{ color: '#fff' }} size={30} />
            </Button>
          ) : (
            <Button className="btn-bg  text-white fs-6" onClick={handleSubmitAddUser}>
              Save
            </Button>
          )}
          <Button onClick={handleClose} className="btn-bg  bg-secondary text-white fs-6">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={message} autoHideDuration={2000} onClose={handleCloseMessage} className="ic">
        <Alert
          onClose={handleCloseMessage}
          severity="success"
          sx={{ width: '100%' }}
          style={{ background: 'rgb(46, 125, 50)', color: '#fff' }}
        >
          success! User Created Successfully
        </Alert>
      </Snackbar>
    </>
  );
};

export default AddUser;
