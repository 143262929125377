import { useLocation, Navigate, Outlet, useNavigate } from 'react-router-dom';

const RequireAuth = ({ allowedRoles }) => {
  const location = useLocation();
  const roles = localStorage.getItem('permissions').split(',');
  const userEmail = localStorage.getItem('uesrEmail');
  let navigate = useNavigate();

  return userEmail === null ? (
    <Navigate to="/login" state={{ from: location }} replace />
  ) : roles === null ? (
    <Navigate to="/login" state={{ from: location }} replace />
  ) : roles.find((role) => allowedRoles?.includes(role)) ? (
    <Outlet />
  ) : userEmail ? (
    <Navigate to="/Unauthorized" state={{ from: location }} replace />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default RequireAuth;
